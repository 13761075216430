import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import App from "../components/App";
import Frame from "../components/Frame";
import SEO from "../components/SEO";

export default ({ location }) => (
  <App>
    <Header />
    <SEO page={{ title: "Contact", pathname: location.pathname }} />
    <Frame>
      <Content>
        <h1>Contact</h1>
        <p>Contact forms are horrible! Write me an email <a href="mailto:michal@michalzalecki.com">michal@michalzalecki.com</a>!</p>
        <p>You can find me on <a href="https://twitter.com/MichalZalecki">Twitter</a>, <a href="https://github.com/MichalZalecki">GitHub</a>, and <a href="https://www.linkedin.com/in/michal-zalecki/">LinkedIn</a>.</p>
      </Content>
    </Frame>
    <Footer />
  </App>
);

const Content = styled.div`
  padding: 26px 0 50px;
  grid-column: 5 / 21 !important;
  @media (max-width: 736px) {
    grid-column: 2 / 24 !important;
  }
`;
